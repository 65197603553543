@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font Faces */
@font-face {
  font-family: "JumperPersonalUseOnly";
  src: local("JumperPersonalUseOnly"),
       url("./assets/fonts/jumper-font/JumperPersonalUseOnlyThin-ZVLDB.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope-Regular";
  src: local("Manrope-Regular"),
       url("./assets/fonts/Manrope/static/Manrope-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mohol-Regular";
  src: local("Mohol-Regular"),
       url("./assets/fonts/Mohol-Regular/Mohol-Regular.otf") format("opentype");
}

/* Global Styles */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1D1D1D;
  font-family: 'Manrope-Regular', sans-serif;
  overflow-x: hidden;
}

/* Components */
@layer components {
  .dropdown-content {
    @apply w-full bg-transparent rounded-md overflow-hidden transition-all duration-300 ease-out;
    max-height: 0;
    opacity: 0;
  }

  .dropdown-content.active {
    max-height: 200px;
    opacity: 1;
  }

  .jumperHeading {
    @apply text-white font-semibold text-4xl;
    font-family: "JumperPersonalUseOnly", serif;
  }

  .headerText {
    @apply text-white font-semibold text-3xl;
    font-family: "JumperPersonalUseOnly", serif;
  }

  .primaryText {
    @apply text-white text-base;
    font-family: "Manrope-Regular", sans-serif;
  }

  .card {
    @apply bg-[#0d0d0d];
  }
}

/* Custom Scrollbar Styles */
.custom-scroll::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: rgba(32, 33, 37, 0);
  border-radius: 50px;
  opacity: 0;
}

.custom-scroll::-webkit-scrollbar-track {
  background: transparent;
  opacity: 0;
}

.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: rgba(32, 33, 37, 0.2) transparent;
}

/* Font Utility Classes */
/* .jumper {
  font-family: "JumperPersonalUseOnly", serif;
}

.manrope {
  font-family: "Manrope-Regular", sans-serif;
} */

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp, 2);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.range-slider {
  margin-top: 10px;
  height: 6px !important;
}
.range-slider .range-slider__thumb {
  width: 18px !important;
  height: 18px !important;
  background: #88F67E !important;
}
.range-slider .range-slider__range {
  background: #88F67E !important;
}

.slick-track {
  margin: 0 !important;
}


.custom-checkbox input[type="checkbox"] {
  display: none;
}
.custom-checkbox .checkmark {
  position: relative;
  width: 16px;
  height: 16px;
  /* background-color: #ddd; */
  border: 1px solid #88F67E; 
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: transparent;
}
.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #88F67E;
  transform: rotate(45deg);
  border-radius: 50%;
}
.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}



